import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Container, Grid, Icon } from 'semantic-ui-react';

import ForgotPasswordForm from 'src/components/auth/ForgotPasswordForm';
import ButtonLink from 'src/components/shared/ButtonLink';
import LinkWithPrevPath from 'src/components/LinkWithPrevPath';
import SEO from 'src/components/SEO';
import Actions from 'src/state/root-actions';

import * as authStyles from 'src/styles/auth.module.scss';

const LoginHelpPage = ({ location, dispatch }) => {
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [email, setEmail] = useState();
    const submitEmail = (email) => {
        setIsEmailSent((showSentEmailState) => !showSentEmailState);
        setEmail(email);
    };

    // TODO: Ask ashley on what to do for resend email onSuccess and onError
    const onFailure = (message) => console.log(message);

    const handleClick = () => {
        dispatch(
            Actions.auth.forgotPassword.trigger({
                email,
                onFailure,
            })
        );
    };

    return (
        <div className={authStyles.authWrapper}>
            <SEO title="Pvolve Reset Password" />
            {isEmailSent ? (
                <>
                    <Container textAlign="center">
                        <h2 className="margin-top--small">Check your email.</h2>
                    </Container>
                    <Grid centered columns={3}>
                        <Grid.Row>
                            <Grid.Column>
                                <p className="secondaryText margin-top--small">
                                    An email was sent to {email}.
                                </p>
                                <p className="secondaryText margin-top--small">
                                    Click the link in the email to reset your password.
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Button as={LinkWithPrevPath} to="/continue" primary fluid>
                                    GOT IT
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <p className="secondaryText margin-top--small">
                                    Didn't get it? Check your spam or junk folder. If it's still not
                                    there,{' '}
                                    <ButtonLink
                                        role="button"
                                        className="resend-email"
                                        onClick={handleClick}
                                    >
                                        resend email
                                    </ButtonLink>
                                    .
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </>
            ) : (
                <>
                    <Grid columns={2} centered>
                        <Grid.Row>
                            <Grid.Column>
                                <p>
                                    Enter your account email, and we'll send you an email to reset
                                    your password.
                                </p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <ForgotPasswordForm
                        email={location?.state?.email}
                        buttonLabel="SEND EMAIL"
                        onSubmit={submitEmail}
                    />
                    <Grid columns={2} centered>
                        <Grid.Row>
                            <p>
                                <LinkWithPrevPath to="/continue">
                                    <Icon name="arrow left" />
                                    <b>I remember now. Go back.</b>
                                </LinkWithPrevPath>
                            </p>
                        </Grid.Row>
                    </Grid>
                </>
            )}
        </div>
    );
};

export default connect()(LoginHelpPage);
